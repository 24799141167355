.iconLine {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-block: 30px;

  @media (max-width: 1029px) {
    margin-bottom: 0;
  }
  @media (max-width: 870px) {
    display: none;
  }

  &__card {
    display: flex;
    justify-content: space-between;
    column-gap: 30px;

    @media (max-width: 1080px) {
      column-gap: 20px;
      justify-content: center;
    }

    @media (max-width: 900px) {
      column-gap: 10px;
    }

    &-item {
      display: flex;
      justify-content: center;
      align-items: center;
      column-gap: 14px;

      .icon-item {
        svg {
          width: clamp(48px, 28px + 2vw, 62px);
          height: clamp(48px, 28px + 2vh, 62px);
          color: #ffa435;
        }
      }

      .text-item {
        display: flex;
        flex-direction: column;

        h3 {
          font-size: clamp(0.7em, 0.1em + 1vw, 1.2em);
        }

        p {
          font-size: clamp(0.7em, 0.1em + 1vw, 1em);
        }
      }
    }
  }
}

