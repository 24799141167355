.categoryPage {
  flex: 1;
  margin-bottom: 30px;

  .categories-back-button {
    background-color: #e76712;
    padding: 10px 20px;
    border-radius: 5px;
    color: #fff;
    margin-inline: auto;
    display: block;
    width: fit-content;
    text-align: center;
    margin-top: 30px;
  }

  &__inner {
    display: flex;
    flex-direction: row;
    justify-content: space-around;

    @media (max-width: 765px) {
      flex-direction: column;
    }
  }

  &__subcats {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-content: center;
    column-gap: 20px;
    row-gap: 20px;
    flex-wrap: wrap;
    margin-bottom: 30px;

    img {
      width: 200px;
      object-fit: cover;
      margin: 0 auto;
    }

    li {
      text-align: center;
      text-transform: uppercase;
      width: 250px;
      height: 200px;

      @media (max-width: 765px) {
        width: 220px;
      }

      a {
        color: #2c3e50;
        text-align: center;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        align-items: center;

        h2 {
          font-size: 14px;
        }
      }
    }
  }
}

.sidebar__subcategories {
  display: block;
  transition: max-height 0.4s ease-in-out;
  max-height: 0;
  width: 100%;
  margin-block: 5px;
  margin-top: 10px;
  background-color: #2c3e50;
  overflow: hidden;

  a {
    color: #EDEDED;
  }

  li {
    padding-block: 12px;
    padding-left: 20px;

    &:hover {
      background-color: rgba(255, 255, 255, 0.5);
    }


  }
}

.sidebar__category {
  width: 280px;
  padding: 10px;
  margin-block: 5px;
  background-color: #ffa435;
  border-radius: 5px;
  cursor: pointer;

  &.active .toggle-icon {
    content: '-';
  }
}

.sidebar__category.active + .sidebar__subcategories {
  max-height: 1000px;
}

.toggle-icon {
  margin-left: auto;
}

@media (max-width: 1024px) {
  .sidebar__category {
    width: auto;
    padding: 10px 0 10px 10px;
  }

}

.category-label {
  display: none;
  text-align: center;
  padding: 10px;
  background-color: #ffa435;
  color: #fff;
  cursor: pointer;
}

@media (max-width: 765px) {
  .sidebar {
    display: none;
  }

  .category-label {
    display: block;
  }

  .category-label {
    text-align: center;
    padding: 10px;
    background-color: #ffa435;
    color: #fff;
    cursor: pointer;
  }
}





