.product {
  display: flex;
  flex: 1;
  flex-direction: column;
  row-gap: 30px;

  @media (max-width: 1031px) {
    row-gap: 20px;
  }
  @media (max-width: 821px) {
    row-gap: 10px;
  }

  h1 {
    margin-block: 0;
    margin-top: 10px;
  }

  &__item {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    column-gap: 20px;
    border: 2px solid #f5f5f5;
    border-radius: 5px;
    box-shadow: 0 2px 10px 1px rgba(0, 0, 0, 0.1);
    margin-inline: 10px;
    padding-inline: 20px;

    @media (max-width: 784px) {
      flex-direction: column-reverse;
    }

    &-text {
      width: 50%;
      display: flex;
      flex-direction: column;
      row-gap: 20px;
      padding-block: 20px;

      @media (max-width: 1031px) {
        width: 60%;
      }
      @media (max-width: 821px) {
        width: 70%;
      }
      @media (max-width: 784px) {
        width: 100%;
      }

      p {
        font-weight: 700;
      }

      ul {
        padding-left: 20px;
        line-height: 1.5em;
        margin-top: 10px;

        li {
          margin-bottom: 5px;
        }
      }

      a {
        width: 250px;
      }

    }


    &-file {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 50px;
      text-align: center;
      text-transform: uppercase;
      background-color: #e76712;
      border-radius: 5px;
      margin-inline: 0 auto;
      color: #EDEDED;
      font-weight: 600;
      font-size: 14px;

      a {
        text-decoration: none;
      }
    }

    .imageFile {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: relative;

      &__image {

        @media (max-width: 784px) {
          display: none;
        }

        img {
          width: 100%;
          height: 300px;
          object-fit: cover;
        }
      }
    }
  }
}


.back-button {
  width: 100px;
  text-align: center;
  text-transform: uppercase;
  background-color: #e76712;
  padding: 10px 20px;
  border-radius: 5px;
  color: #fff;
  margin: 0 0 20px 20px;
}