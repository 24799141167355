.vacancies {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex: 1;

  &__text {

    &-vac {
      margin-block: 20px;
      font-weight: 700;
    }
    &-ul {
      margin-top: 10px;
      margin-bottom: 20px;
      margin-left: 10px;
    }

    &-contacts {
      margin-top: 20px;
    }
  }

  &__image {
    width: 300px;

    @media (max-width: 768px) {
      display: none;
    }
  }
}