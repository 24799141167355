.info {
  display: flex;
  flex-wrap: wrap;
  row-gap: 20px;
  column-gap: 20px;
  justify-content: center;
  margin-inline: auto;
  margin-bottom: 20px;
  background-color: #EDEDED;
  padding-block: 30px;

  @media (max-width: 1029px) {
    display: none;
  }

  &__card {
    display: flex;
    justify-content: center;

    width: 30%;

    &__img {
      width: 100%;
      height: 100px;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    &__text {

      h4 {
        text-transform: uppercase;
        font-size: 18px;
        line-height: 22px;
        margin-block: 10px;
        font-weight: 500;
        text-align: center;
      }
    }
  }
}

.desktop-only {
  display: none;
}

@media only screen and (min-width: 1029px) {
  .desktop-only {
    display: block;
  }

  .accordion-mobile {
    display: none;
  }
}