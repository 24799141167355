* {
    padding: 0;
    margin: 0;
    list-style: none;
    outline: none;
    font-family: 'Nunito', Roboto, system-ui, Tahoma, sans-serif;
    box-sizing: border-box;
}

html {
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
}

body {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
}

a,
span,
p,
b,
h1,
h2,
h3,
h4,
h5 {
    color: #000000;
}

h1, h2 {
    font-weight: 500;
    /*font-size: 28px;*/
    font-size: clamp(1.4rem, 0.25rem + 1.667vw, 1.75rem);
    line-height: 30px;
    margin-block: 30px;
    text-align: center;
}

h2 {
    text-align: left;
}

h3 {
    font-size: clamp(0.875rem, 0.25rem + 1.667vw, 1.75rem);
}

a {
    text-decoration: none;
}

.wrapper {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}
