.about {
  text-align: center;
  padding-block: 30px;
  background-color: #ededed;

  &__text {
    display: flex;
    justify-content: center;
    column-gap: 30px;
    margin-top: 30px;
  }

  h2 {
    text-align: center;
    margin-inline: 10px;
    margin-block: 0;
  }

  h3 {
    margin-block: 10px;
    margin-inline: 10px;
    font-size: clamp(1rem, 0.3rem + 1.667vw, 1.2rem);
  }

  ul {
    margin-left: 20px;

    li {
      list-style: disc;
      text-align: left;
      font-size: clamp(1rem, 0.3rem + 1.667vw, 1.2rem);
      margin-bottom: 5px;
      margin-inline: 10px;
    }
  }

  p {
    margin: 20px 10px 0 10px;
    font-size: clamp(1rem, 0.3rem + 1.667vw, 1.2rem);
  }
}