.categories {
  flex: 1;
  margin-inline: 20px;
  padding-block: 30px;

  @media (max-width: 1029px) {
    padding-block: 0;
    padding-bottom: 30px;
  }

  &__items {
    display: flex;
    justify-content: space-between;
    column-gap: 20px;
    flex-wrap: wrap;
    row-gap: 20px;

    @media (max-width: 1060px) {
      justify-content: center;
    }

    &-item {
      width: clamp(200px, 240px, 240px);
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      border: 1px solid #ccc;
      border-radius: 5px;
      padding: 20px;

      a {
        display: flex;
        flex-direction: column;
        align-items: center;

        img {
          width: clamp(180px, 20px, 220px);
        }

        p {

        }
      }
    }
  }
}
