.sertificates {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  column-gap: 20px;
  margin-top: 20px;
  flex-wrap: wrap;

  &__item {
    display: flex;
    flex-direction: column;
    justify-content: center;

    h2 {
      text-align: center;
      margin-bottom: 0;
    }

    img {
      width: 400px;
      height: 580px;
      object-fit: contain;
      margin-block: 20px;

      @media (max-width: 440px) {
        width: 100%;
        height: 100%;
        margin: 0 auto;
      }
    }
  }

}