.contacts {
  flex: 1;

  &__inner {
    display: flex;
    justify-content: center;
    row-gap: 30px;
    column-gap: 30px;
    margin-bottom: 30px;

    &-tel {
      display: flex;
      flex-direction: column;
      justify-content: center;

      p {
        display: flex;
        margin: 3px 0;

        a:hover {
          color: #e76712;
        }
      }
    }

    &-address {
      p {
        line-height: 1.9em;
      }
    }

    &-icons {
      margin-right: 5px;
      padding-bottom: 2px;
      color: #ffa435;
    }
  }
}

.ymap {
  height: 500px;
  width: 100%;
}

.mapProp {
  width: 100%;
  height: 500px;
}