.manufacturers {
  margin-block: 30px;

  @media (max-width: 768px) {
    display: none;
  }

  &__text {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    width: 100%;
    background-color: #EDEDED;
    text-transform: uppercase;
    margin-block: 20px;
    text-align: center;

    h5 {
      font-size: 20px;
      font-weight: 500;
    }
  }

  &__list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
    grid-gap: 20px;
    justify-content: center;
    max-width: 1400px;
    margin: 0 auto;

    div {
      max-width: 100%;
      max-height: 100%;

      img {
        max-width: 100%;
        max-height: 100%;
        object-fit: contain;
      }
    }
  }
}