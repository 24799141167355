.header {
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-inline: 20px;
  border-bottom: 1px solid #c1c1c1;
  background-color: #EDEDED;

  &__logo {
    width: 200px;
    height: 40px;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  &__nav {
    margin-left: auto;

    &-list {
      display: flex;
      list-style: none;

      li {

        a {
          padding: 5px;

          &:hover {
            color: #e76712;
          }
        }
      }
    }

    &-item {
      font-size: 16px;

      &.active {
        div {
          color: #e76712;
        }
      }


      div {
        cursor: pointer;

        &:hover {
          color: #e76712;
        }
      }

      &:nth-child(n+2) {
        margin-left: 20px;
      }
    }


    ul {
      position: relative;

      .submenu {
        position: absolute;
        top: 30px;
        left: 60px;
        width: auto;
        height: auto;
        background-color: #dcdcdc;
        z-index: 99;

        li {
          padding-block: 10px;
          margin-inline: 10px;
          transition: background-color 0.3s ease;

          &:hover {
            background-color: rgba(255, 255, 255, 0.5);
            transition: background-position 0.3s ease;
          }

          a {
            color: #000;
          }
        }
      }

      li {
        img {
          height: 25px
        }
      }
    }
  }

  &__menu-button {
    display: none;
  }

  @media (max-width: 768px) {
    &__nav {
      position: fixed;
      top: 0;
      left: -225px;
      display: flex;
      flex-direction: column;
      width: 220px;
      height: 100%;
      background-color: #fff;
      border: 1px solid #e76712;
      box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
      transition: transform 0.3s linear;
      z-index: 999;

      ul {
        .submenu {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          padding: 30px 0 0 30px;
          background-color: #fff;
          z-index: 99;
        }
      }

      &.active {
        transform: translateX(100%);
      }

      &-list {
        flex: 1;
        display: flex;
        flex-direction: column;
        padding-top: 40px;
        padding-left: 60px;
      }

      &-item {
        margin: 10px 0;
        font-weight: 500;

        div {
          padding-left: 5px;
        }

        &:nth-child(n+2) {
          margin-left: 0;
        }
      }
    }

    &__menu-button {
      display: block;
      margin-left: auto;
      background-color: transparent;
      padding: 0;
      border: 0;

      img {
        width: 64px;
        height: 32px;
      }
    }
  }
}


