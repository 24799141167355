.slider {
  position: relative;
  max-width: 1440px;
  margin-inline: auto;
  margin-bottom: 20px;



  .text {
    position: absolute;
    top: 20%;
    left: 10%;
    max-width: 450px;
    line-height: 1.5em;

    h2 {
      margin-bottom: 20px;
      text-transform: uppercase;
    }

    h3 {
      font-weight: 600;
    }

    ul {
      li {
        margin-block: 10px;
      }
    }
  }

  .slide {
    width: 100%;
    margin: 0 auto;

    img {
      width: 100%;
      height: 400px;
      object-fit: cover;
    }
  }
}

.swiper-button-prev, .swiper-button-next {
  color: #ffa435;
}

.mySwiper {
}

@media (max-width: 1028px){
  .slider {
    display: none;
  }
}