.footer {
  max-width: 1440px;
  display: flex;
  flex: 0;
  flex-direction: column;
  background-color: #1C1C1C;
  color: #fff;

  &__contacts {
    display: flex;
    flex-direction: row;
    column-gap: 20px;
    justify-content: space-evenly;
    align-items: flex-start;
    padding-block: 20px;

    p > a {
      color: #fff;
      margin-left: 5px;

      &:hover {
        color: #ffcca9;
      }
    }

    &-address {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    &-tel {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    &-working {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    &__icons {
      margin-right: 5px;
    }

    p {
      display: flex;
      margin: 5px 0;
      color: #fff;
    }
  }
}


.footer__text {
  display: flex;
  justify-content: center;
  height: 40px;
  width: 100%;
  background-color: #EDEDED;
  text-transform: uppercase;
  margin-block: 20px;
  text-align: center;
  align-items: center;

  h5 {
    font-size: 20px;
    font-weight: 500;
  }
}

@media (max-width: 655px) {
  .footer__contacts {
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: center;
    padding-block: 20px;
    text-align: left;
    margin: 0 auto;
  }
}